<template>
  <div class="d-selector-workflow-card">
    <d-quiz-indicator v-show="!workflow.hideIndicator" variant="circle" :workflowId="workflow.id" />
    <div class="poster-container">
      <div class="video-div">
        <d-video-get-links-box
          :multiStepIds="workflow.steps"
          :poster="posterLink"
          :isPlayingIndex="isPlayingIndex"
          :index="idx"
          @set-playing-index="$emit('set-playing-index', { idx: idx })"
        ></d-video-get-links-box>
      </div>
      <div class="time-step-div">
        <span autotest="add-content-workflow-length"> {{ videoDuration(workflow) }} </span>
        <span v-if="workflow.published || workflow.processed">
          <span class="grey-dot"></span>
          <span autotest="add-content-workflow-steps">
            {{ $tc("all.numStep", getStepsLength(workflow.steps)) }}
          </span>
        </span>
      </div>
    </div>
    <div class="card-content-div">
      <p class="video-title text-no-wrap text-truncate">
        <v-tooltip top open-delay color="#ffffff">
          <span slot="activator" autotest="add-content-workflow-title">{{ getWorkflowDisplayTitle(workflow) }}</span>
          <span style="color: #3f3f3f">{{ workflow.title }}</span>
        </v-tooltip>
      </p>
      <v-tooltip top left open-delay color="#ffffff">
        <div slot="activator" class="bottom-div center-horizontally text-no-wrap">
          <d-avatar v-if="workflow.author.displayName" :size="24" :userId="workflow.author.uid" />
          <span v-if="workflow.author.displayName" class="ml-2 text-truncate" autotest="add-content-workflow-owner">{{
            workflow.author.displayName
          }}</span>
          <span v-if="workflow.author.displayName && workflow.published">
            &nbsp;
            <span class="grey-dot"></span>
            &nbsp;
          </span>
          <span v-if="workflow.published">{{ timeSince(workflow.publishedDate || workflow.publishedAt) }}</span>
          <v-spacer></v-spacer>
          <div v-if="workflow.privateWorkspace">
            <font-awesome-icon :icon="['fas', 'lock-keyhole']" />
            <span class="private-label">{{ $t("all.private") }}</span>
          </div>
        </div>
        <span style="color: #3f3f3f">{{ workflow.author.displayName }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DVideoGetLinksBox from "../ui_components/DVideoGetLinksBox.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import { getDisplayTitle } from "@/js/video-player/title-display.js";

export default {
  name: "DSelectorWorkflowCard",
  props: {
    workflow: Object,
    idx: Number,
    isPlayingIndex: Number,
  },
  mounted() {
    this.posterLink = this.workflow.poster;
  },
  watch: {
    workflow: function () {
      this.posterLink = "";
      setTimeout(() => {
        this.posterLink = this.workflow.poster;
      }, 50);
    },
  },
  // mixins: [],
  data() {
    return {
      posterLink: "",
    };
  },
  components: {
    IconBase,
    DVideoGetLinksBox,
    DAvatar,
    DQuizIndicator: () => import("@/components/DQuizIndicator.vue"),
  },
  methods: {
    getWorkflowDisplayTitle(workflow) {
      const displayLang = this.$i18n.locale;
      return getDisplayTitle(workflow, displayLang);
    },
    timeSince(date) {
      const timeStamp = new Date(date.seconds ? date.seconds * 1000 : date).getTime();
      const now = new Date();
      return this.timeDifference(now, timeStamp);
    },
    timeDifference(current, previous) {
      const msPerMinute = 60 * 1000;
      const msPerHour = msPerMinute * 60;
      const msPerDay = msPerHour * 24;
      const msPerMonth = msPerDay * 30;
      const msPerYear = msPerDay * 365;
      const elapsed = current - previous;
      if (elapsed < msPerMinute) {
        const secondNum = Math.round(elapsed / 1000);
        return this.$tc("explorer.secondsAgo", secondNum);
      } else if (elapsed < msPerHour) {
        const minuteNum = Math.round(elapsed / msPerMinute);
        return this.$tc("explorer.minutesAgo", minuteNum);
      } else if (elapsed < msPerDay) {
        const hourNum = Math.round(elapsed / msPerHour);
        return this.$tc("explorer.hoursAgo", hourNum);
      } else if (elapsed < msPerMonth) {
        const dayNum = Math.round(elapsed / msPerDay);
        return this.$tc("explorer.daysAgo", dayNum);
      } else if (elapsed < msPerYear) {
        const monthNum = Math.round(elapsed / msPerMonth);
        return this.$tc("explorer.monthsAgo", monthNum);
      } else {
        const yearNum = Math.round(elapsed / msPerYear);
        return this.$tc("explorer.yearsAgo", yearNum);
      }
    },
    videoDuration(workflow) {
      let sec = workflow.videoDuration;
      if (!sec || sec === Infinity) return "0:00";
      let minutes = Math.floor(sec / 60);
      let seconds = sec - minutes * 60;
      let padding = seconds < 10 ? "0" : "";
      return minutes + ":" + padding + Math.floor(seconds);
    },
    getStepsLength(steps) {
      if (!steps) return 0;
      else return steps.length;
    },
  },
  computed: {},
};
</script>

<style scoped>
.private-label {
  text-transform: capitalize;
  display: inline-block;
  margin-left: 4px;
}
.d-selector-workflow-card {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}
.poster-container {
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0px;
  position: relative;
  background-color: #ffffff;
  overflow: visible;
}
.video-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.time-step-div {
  position: absolute;
  bottom: 12px;
  right: 12px;
  height: 22px;
  border-radius: 11px;
  background-color: #0c0c0e80;
  padding: 3px 8px 0 8px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  z-index: 2;
}
.grey-dot {
  height: 3px;
  width: 3px;
  background-color: #8d909f;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
}
.card-content-div {
  position: relative;
  width: 100%;
  padding: 12px 14px;
  overflow: hidden;
}
.video-title {
  position: relative;
  color: #3f3f3f;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  width: 100%;
  text-align: left;
  height: 22px;
  margin-bottom: 16px;
  padding-top: 2px;
}
.bottom-div {
  position: relative;
  width: 100%;
  height: 24px;
  color: #9b9b9b;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
