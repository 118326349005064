<template>
  <div class="playerlist-builder" @mousedown="finishEditing()">
    <v-toolbar dark color="#2C2D32" height="70px" class="pt-0 builder-toolbar">
      <button class="tool-bar-icon" @click="clickClosePlaylistBuilder()" autotest="skill-x">
        <icon-base width="22" height="22">
          <d-icon-close2 />
        </icon-base>
      </button>
      <div class="edit-poster-div" @click="onclickEditPoster()">
        <div class="poster-container">
          <div class="poster-icon">
            <icon-base color="white" height="16" width="16" class="diagram-btn-icon">
              <d-icon-image />
            </icon-base>
          </div>
          <d-image v-show="playlistPosterUrl" :aspectRatio="16 / 9" :src="playlistPosterUrl" class="video-poster" />
        </div>
      </div>
      <p
        v-if="!isEditingPlaylistName && playlistData.playlistTitle != ''"
        class="white--text title font-weight-regular text-no-wrap text-truncate mt-3"
        :class="{ 'title-border': !playlistData.published }"
        @mouseover="showEditIcon = true"
        @mouseleave="showEditIcon = false"
        @click="clickTitle()"
      >
        {{ playlistData.playlistTitle }}
      </p>
      <div v-else class="editVideo__editInputWrapper">
        <input
          ref="inputPlaylistTitle"
          class="edit-video-title"
          :class="{ 'title-empty': playlistData.playlistTitle == '' }"
          :size="playlistData.playlistTitle.length"
          type="text"
          maxlength="90"
          :placeholder="$t('editor.hintNoEmptyTitle')"
          v-model.trim="editedPlaylistTitle"
          @change="madeChanges = true"
          @keydown="handleInput($event)"
          @mouseover="isTypingTitle = true"
          @mouseleave="isTypingTitle = false"
        />
        <span v-if="playlistTitleNotAllowed" class="red--text editVideo__editInput--invalid">{{
          $t("rules.invalidName")
        }}</span>
      </div>
      <icon-base
        color="#898C9B"
        width="20"
        height="20"
        class="edit-icon ml-2"
        :class="{ 'edit-icon-show': showEditIcon && !playlistData.published }"
      >
        <d-icon-pen />
      </icon-base>
      <div class="publish-tag" :class="{ 'publish-tag-move': showEditIcon && !playlistData.published }">
        <span v-if="playlistData.published" class="publish-tag-text">
          <span class="green-dot"></span>
          {{ $t("all.published") }}
        </span>
        <span v-else class="publish-tag-text">
          <span class="red-dot"></span>
          {{ $t("all.unpublished") }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <d-button
        outline
        round
        color="#4689f4"
        class="body-2 text-capitalize btn-width"
        @click="saveChanges()"
        :disabled="!madeChanges"
        dark
        autotest="skill-save"
        >{{ $t("all.save") }}</d-button
      >
      <d-button
        v-if="!playlistData.published"
        round
        color="#4689f4"
        class="elevation-0 body-2 text-capitalize btn-width"
        :disabled="playlistData.list.length == 0"
        @click="clickPublishBtn()"
        dark
        autotest="skill-publish"
        >{{ $t("builder.publish") }}</d-button
      >
      <d-button
        v-else
        round
        color="#4689f4"
        class="elevation-0 body-2 text-capitalize btn-width no-transform"
        @click="clickEditPlylistBtn()"
        dark
        autotest="skill-edit-skill"
        >{{ $t("skills.editSkill") }}</d-button
      >
    </v-toolbar>
    <div class="tabs-div" :class="{ 'tabs-div--disabled': isSaving }" :style="{ '--num-of-tabs': tabs.length }">
      <router-link
        v-for="(tab, index) in tabs"
        :to="`/skillsbuilder/${playlistId}/${tab.subpath}`"
        :key="tab.subpath"
        class="tab-btn"
        :class="{ 'tab-btn--active': currentTabIdx === index }"
      >
        <button :autotest="tab.autotest">
          {{ tab.text }}
        </button>
      </router-link>
      <div class="blue-bar" />
    </div>

    <!-- skill-wrapper -->
    <div v-if="currentTabIdx == 0">
      <div class="add-div" v-if="playlistData.list.length > 0">
        <div class="add-center">
          <div class="add-workflow-btn">
            <d-button
              outline
              round
              color="#4689f4"
              class="elevation-0 body-2 text-capitalize btn-width ma-0 no-transform"
              @click="openAddWorkflow()"
              autotest="skillsbuilder-add-content"
              >{{ $t("skills.addContent") }}</d-button
            >
          </div>
        </div>
      </div>
      <div class="playlist-wrapper">
        <div class="playlist-all-div">
          <d-skills-drag
            ref="dPlaylistDrag"
            v-model="playlistData.list"
            :isPublished="playlistData.published"
            @made-changes="madeChanges = true"
            @show-unpublish-list-popup="showEditPlaylistPopup = true"
          ></d-skills-drag>
        </div>
      </div>

      <div v-if="playlistData.list.length == 0" class="new-list-msg">
        <div class="fullscreen-center">
          <div class="add-new-workflow-div">
            <icon-base class="mb-2" width="144" height="144">
              <d-icon-new-list />
            </icon-base>
            <p class="new-list-msg-text">{{ $t("skills.noContentMsg") }}</p>
            <d-button
              outline
              round
              color="#4689f4"
              class="elevation-0 body-2 text-capitalize btn-width-lg ma-0"
              light
              @click="openAddWorkflow()"
              >{{ $t("skills.addContent") }}</d-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Unpublish skill confirmation -->
    <d-confirmation-popup
      light
      v-model="showEditPlaylistPopup"
      :title="$t('skills.editSkill')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.popupBtnEdit')"
      :content="$t('skills.popupMsgEditSkill')"
      @clickActionBtn="updatePlaylist('unpublish')"
    ></d-confirmation-popup>

    <!-- Publish skill confirmation -->
    <d-confirmation-popup
      light
      v-model="showPublishPlaylistPopup"
      :title="$t('skills.publishSkill')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.popupBtnPublish')"
      :content="$t('skills.popupMsgPublishSkill')"
      @clickActionBtn="publishPlaylist()"
    ></d-confirmation-popup>

    <!-- close Confirmation -->
    <d-confirmation-popup
      light
      v-model="closeConfirmation"
      :title="$t('skills.closeSkill')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('editor.popupBtnClose')"
      :content="$t('builder.popupMsgCloseSettings')"
      @clickActionBtn="closePlaylistBuilder()"
    ></d-confirmation-popup>

    <!-- SkillsWorkflowSelector -->
    <skills-workflow-selector
      v-if="initWorkflowSelector"
      :show="showWorkflowSelector"
      :groupId="playlistData.group"
      :workflowList="playlistData.list"
      :groupDictionary="groupDictionary"
      @close-playlist-selector="showWorkflowSelector = false"
      @add-more-workflows="addMoreWorkflow($event)"
    ></skills-workflow-selector>

    <!-- BuilderPosterPopup -->
    <builder-poster-popup
      v-if="showPosterPopup"
      posterType="skill"
      :playlistData="playlistData"
      @close-poster-popup="showPosterPopup = false"
      @update-poster="updatePoster($event)"
    >
    </builder-poster-popup>

    <d-alert v-model="alert.isSaving" manualClose type="info" :message="$t('editor.alertIsSaving')"></d-alert>
    <d-alert v-model="alert.isFailedSave" type="error" :message="$t('skills.alertFailedSaveSkill')"></d-alert>
    <d-alert v-model="alert.isFailedPublish" type="error" :message="$t('skills.alertFailedPublishSkill')"></d-alert>

    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
    <d-alert v-model="emptyTitleAlert" type="error" :message="$t('editor.alertNoEmptyTitle')"></d-alert>
    <d-alert v-model="alertDue.show" :type="alertDue.type" :message="alertDue.msg"></d-alert>

    <router-view
      ref="skillBuilderRouterView"
      class="router-div"
      :skillTitle="playlistData.playlistTitle"
      :playlistData="playlistData"
      :groupDictionary="groupDictionary"
      :madeChanges="madeChanges"
      :isSaving="isSaving"
      @add-users-teams="addUsersOrTeams($event, playlistData, isNewPlaylist)"
      @made-changes="madeChanges = true"
      @save-changes="saveChanges()"
      @get-updated-playlist="readyToGetExistingPlayListData(playlistId)"
      @add-certifier="addCertifier($event)"
      @remove-certifier="removeCertifier($event)"
      @remove-all-certifier="removeAllCertifiers()"
      @saving-start="(isSaving = true), (alert.isSaving = true)"
      @saving-end="(isSaving = false), (alert.isSaving = false)"
    ></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconNewList from "@/components/icons/colored/DIconNewList.vue";
import DIconImage from "@/components/icons/DIconImage.vue";
import DSkillsDrag from "@/components/ui_components/DSkillsDrag.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import SkillsWorkflowSelector from "@/components/DSkills/SkillsWorkflowSelector.vue";
import BuilderPosterPopup from "@/components/DBuilder/BuilderPosterPopup.vue";
import DImage from "@/components/ui_components/DImage.vue";
import MixinDB from "@/components/MixinDB.vue";
import {
  notifySkillHasAssignedToUsersOrTeams,
  notifySkillHasChangedPublishedState,
} from "@/server/notifications-server.js";
import { saveUpdateSkillDue } from "@/server/skill-due-server";
import { postAssignUsersAndTeamsToPlaylist } from "@/server/api-server.js";
import { getUserData } from "@/js/skill/user-skill.js";
import { getTeamData } from "@/js/skill/team-skill.js";
import { checkPlaylistTitleCharacters } from "@/js/character-validator/character-validator.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters } from "vuex";

export default {
  name: "SkillsBuilder",
  props: {
    groupDictionary: Object,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconPen,
    DIconNewList,
    DSkillsDrag,
    DIconImage,
    DAlert,
    DConfirmationPopup,
    SkillsWorkflowSelector,
    BuilderPosterPopup,
    DImage,
  },
  watch: {
    $route() {
      this.setCurrentTab();
    },
  },
  async created() {
    this.setCurrentTab();
    this.groupId = this.$route.params.groupId;
    this.playlistId = this.$route.params.playlistId;
    if (this.playlistId === "new" || !this.playlistId) {
      // for new skill
      this.isNewPlaylist = true;
      this.playlistId = this.playlistData["id"] = this.generateDocId("playlists");
      this.playlistData["playlistTitle"] = this.$route.params.playlistTitle;
      this.playlistData["organization"] = this.$organization;
      this.playlistData["group"] = this.groupId;
      this.madeChanges = true;
      this.createNewPlaylist("save");
    } else {
      this.getPlaylistData(this.playlistId);
    }
    if (this.groupId) {
      Vue.prototype.$editorGroup = this.groupId;
    }
  },
  data: function () {
    return {
      groupId: "",
      playlistId: "",
      playlistData: {
        playlistTitle: "New Playlist",
        published: false,
        group: "",
        list: [],
      },
      editedPlaylistTitle: "",
      isEditingPlaylistName: false,
      isTypingTitle: false,
      showEditIcon: false,
      madeChanges: false,
      showEditPlaylistPopup: false,
      showPublishPlaylistPopup: false,
      initWorkflowSelector: false,
      showWorkflowSelector: false,
      alert: {
        isSuccess: false,
        isFailedSave: false,
        isFailedPublish: false,
        isSaving: false,
        alertText: "",
      },
      alertDue: {
        show: false,
        type: "",
        msg: "",
      },
      closeConfirmation: false,
      emptyTitleAlert: false,
      currentTabIdx: 0,
      playlistWorkflows: [],
      isNewPlaylist: false,
      showPosterPopup: false,
      isSaving: false,
      isReadyToRestartGetPlayListData: false,
    };
  },
  mixins: [MixinDB],
  methods: {
    readyToGetExistingPlayListData(playlistId) {
      this.isReadyToRestartGetPlayListData = true;
      setTimeout(() => {
        this.isReadyToRestartGetPlayListData = false;
        this.getPlaylistData(playlistId);
      }, 300);
    },
    updatePoster(url) {
      Vue.set(this.playlistData, "poster", url);
      this.saveChanges();
    },
    addCertifier(user) {
      let inArrayIndex = -1;
      if (this.playlistData.certifiers) {
        inArrayIndex = this.playlistData.certifiers
          .map((e) => {
            return e.id;
          })
          .indexOf(user.id);
      } else {
        this.playlistData.certifiers = [];
      }
      if (inArrayIndex === -1) {
        this.playlistData.certifiers.push({
          displayName: user.displayName,
          uid: user.id,
          email: user.email,
          ordering: 0,
        });
        this.playlistData.certifiers.splice();
      }
    },
    removeCertifier(user) {
      const inArrayIndex = this.playlistData.certifiers
        .map((e) => {
          return e.uid;
        })
        .indexOf(user.uid);
      if (inArrayIndex != -1) {
        this.playlistData.certifiers.splice(inArrayIndex, 1);
        this.playlistData.certifiers.splice();
      }
    },
    removeAllCertifiers() {
      this.playlistData.certifiers = [];
      this.playlistData.certifiers.splice();
    },
    isUserAlreadyEnrolled(user) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByQuery("userSkills", [
            {
              field: "uid",
              comparison: "==",
              value: user.id,
            },
            {
              field: "playlistId",
              comparison: "==",
              value: self.playlistId,
            },
          ])
          .then((data) => {
            if (data.length) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            console.log(err);
            loggingError(err);
          });
      });
    },
    isTeamAlreadyEnrolled(team) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByQuery("teamSkills", [
            {
              field: "teamId",
              comparison: "==",
              value: team.id,
            },
            {
              field: "playlistId",
              comparison: "==",
              value: self.playlistId,
            },
          ])
          .then((data) => {
            if (data.length) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            console.log(err);
            loggingError(err);
          });
      });
    },
    async addUsersOrTeams(usersOrTeams, playlistData = { id: "", dueActivated: false }, isNewPlaylist = false) {
      this.alert.isSaving = true;
      this.isSaving = true;
      const userList = [];
      const teamList = [];
      usersOrTeams.forEach(function (doc) {
        if (doc.teamId) {
          teamList.push(doc);
        } else {
          userList.push(doc);
        }
      });
      try {
        // remove users already enrolled
        const usersReadyToAdd = [];
        for (let i = 0; i < userList.length; i++) {
          const user = userList[i];
          const isEnrolled = await this.isUserAlreadyEnrolled(user);
          if (!isEnrolled) usersReadyToAdd.push({ id: user.id });
        }

        //remove teams already enrolled
        const teamsReadyToAdd = [];
        for (let i = 0; i < teamList.length; i++) {
          const team = teamList[i];
          const isEnrolled = await this.isTeamAlreadyEnrolled(team);
          if (!isEnrolled) teamsReadyToAdd.push({ id: team.id });
        }
        if (usersReadyToAdd.length === 0 && teamsReadyToAdd.length === 0) return;

        // must save playlist before adding users, otherwise we could have users assigned to ghost playlist
        if (isNewPlaylist) {
          await this.createNewPlaylist();
        } else {
          await this.updatePlaylist();
        }

        const { ok, data, errorMessage } = await postAssignUsersAndTeamsToPlaylist({
          playlistId: playlistData.id,
          users: usersReadyToAdd,
          teams: teamsReadyToAdd,
        });
        if (!ok) throw errorMessage;

        // extract user & team data from response
        const {
          item: { users = [], teams = [] },
        } = data;
        const usersData = users
          .map((user) => {
            const userData = getUserData(user);
            if (!userData) {
              console.warn(`Failed to add user - ${user.id}`);
              return null;
            }
            return userData;
          })
          .filter((user) => user !== null);
        const teamsData = teams
          .map((team) => {
            const teamData = getTeamData(team);
            if (!teamData) {
              console.warn(`Failed to add team - ${team.id}`);
              return null;
            }
            return teamData;
          })
          .filter((team) => team !== null);

        if (this.$refs.skillBuilderRouterView?.fetchSkillUsers) {
          this.$refs.skillBuilderRouterView.fetchSkillUsers();
        }

        // update due dates or send notification
        if (playlistData.dueActivated) {
          this.updateUsersAndTeamsDueDates();
        } else {
          notifySkillHasAssignedToUsersOrTeams({
            vueInstanceRef: this,
            playlistId: playlistData.id,
            users: usersData.map((user) => {
              return {
                id: user.uid,
                email: user.email,
                displayName: user.displayName,
              };
            }),
            teams: teamsData.map((team) => team.teamId),
          });
        }
      } catch (e) {
        console.error(e);
        loggingError(e);
        alert("Error adding users or teams!");
      } finally {
        this.alert.isSaving = false;
        this.isSaving = false;
      }
    },
    setCurrentTab() {
      const findIndex = this.tabs.findIndex(({ name }) => name === this.$route.name);
      this.currentTabIdx = findIndex ?? 0;
    },
    clickEditPlylistBtn() {
      this.showEditPlaylistPopup = true;
    },
    clickPublishBtn() {
      if (this.checkEmptyTitles()) {
        return;
      } else {
        this.showPublishPlaylistPopup = true;
      }
    },
    checkEmptyTitles() {
      if (this.playlistData.playlistTitle == "") {
        this.emptyTitleAlert = true;
        return true;
      } else {
        return false;
      }
    },
    getUpdatedPlaylistItem(item) {
      const self = this;
      return new Promise(function (resolve, reject) {
        let updatedItem;
        if (item.type == "workflows") {
          if (self.$backendType == "ali") {
            self
              .getDocumentByQuery("workflows", [
                {
                  field: "_id",
                  comparison: "==",
                  value: item.id,
                },
              ])
              .then((workflows) => {
                if (workflows.length != 0) {
                  let workflow = workflows[0];
                  updatedItem = self.getUpdatedItem(workflow);
                  resolve(updatedItem);
                } else {
                  item["deleted"] = true;
                  resolve(item);
                }
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            self
              .getDocument("workflows", item.id)
              .then((workflow) => {
                updatedItem = self.getUpdatedItem(workflow);
                resolve(updatedItem);
              })
              .catch((err) => {
                if (err.message == "Missing or insufficient permissions.") {
                  item["noPermission"] = true;
                  resolve(item);
                } else {
                  reject(err);
                }
              });
          }
        } else {
          //it should never get here without bugs
          resolve(item);
        }
      });
    },
    getUpdatedItem(workflow) {
      let updatedItem = {
        type: "workflows",
        title: workflow.title,
        poster: workflow.poster,
        videoDuration: workflow.videoDuration || 0,
        steps: workflow.steps ? workflow.steps.length : 0,
        id: workflow.id,
        published: workflow.published,
        titleTranslations: workflow.titleTranslations || {},
        privateWorkspace: workflow.privateWorkspace,
      };
      return updatedItem;
    },
    getUpdatedPlaylistData(data) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const promises = [];
        const latestData = [];
        data.list.forEach((item) => {
          promises.push(self.getUpdatedPlaylistItem(item));
        });
        Promise.all(promises)
          .then((items) => {
            data.list = items;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getPlaylistData(playlistId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocument("playlists", playlistId)
          .then(async (data) => {
            self.playlistData = await self.getUpdatedPlaylistData(data);
            resolve();
          })
          .catch((err) => {
            alert("failed to get skill data.");
            reject();
          });
      });
    },
    publishPlaylist() {
      if (this.isNewPlaylist) {
        this.createNewPlaylist("publish");
      } else {
        this.updatePlaylist("publish");
      }
    },
    getPlaylistWorkflows(list) {
      const workflows = [];
      list.forEach((item) => {
        if (item.type == "workflows") {
          workflows.push(item.id);
        }
      });
      return workflows;
    },
    createNewPlaylist(todo) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        self.alert.isSaving = true;
        let publishedBoolean = self.playlistData.published;
        if (todo == "publish") {
          publishedBoolean = true;
        }
        if (todo == "save") {
          publishedBoolean = false;
        }
        const id = self.playlistData["id"];
        self
          .addDocument("playlists", id, {
            id: id,
            playlistTitle: self.playlistData.playlistTitle,
            published: publishedBoolean,
            group: self.playlistData.group,
            list: self.playlistData.list,
            organization: self.playlistData.organization,
            createdAt: await self.getServerTimestamp(),
            createdBy: {
              displayName: self.$user.displayName,
              email: self.$user.email,
              uid: self.$user.uid,
            },
            settings: {
              // strictOrder: false,
              // renewPeriod: 365,
            },
            publishedDate: await self.getServerTimestamp(),
            workflows: self.getPlaylistWorkflows(self.playlistData.list),
            certifiers: self.playlistData.certifiers ? self.playlistData.certifiers : [],
            poster: self.playlistData.poster || null,
          })
          .then(() => {
            // clear selected Workflows list
            self.isNewPlaylist = false;
            self.playlistData.published = publishedBoolean;
            self.madeChanges = false;
            self.alert.isSaving = false;
            if (todo == "publish") {
              self.alert.alertText = self.$t("skills.alertSkillSuccessfullyPublished");
            } else if (todo == "save") {
              self.alert.alertText = self.$t("skills.alertSkillSuccessfullySaved");
            } else {
              self.alert.alertText = self.$t("skills.alertSkillSuccessfullySaved");
            }
            self.alert.isSuccess = true;
            if (self.$route.params.playlistId === "new") {
              self.$router.replace("/skillsbuilder/" + self.playlistData["id"]);
            }
            resolve();
          })
          .catch((err) => {
            self.alert.isSaving = false;
            self.alert.isNotSuccess = true;
            self.alert.alertText = self.$t("skills.alertFailedSavedSkill");
            reject();
          });
      });
    },
    saveChanges() {
      if (this.checkEmptyTitles()) {
        return;
      }
      if (this.isNewPlaylist) {
        this.createNewPlaylist("save");
      } else {
        this.updatePlaylist("save");
      }
    },
    updatePlaylist(todo) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        self.alert.isSaving = true;
        let publishedBoolean = self.playlistData.published;
        if (todo == "publish") {
          publishedBoolean = true;
        }
        if (todo == "unpublish") {
          publishedBoolean = false;
        }
        const id = self.playlistData.id;
        const data = {
          organization: self.$organization,
          playlistTitle: self.playlistData.playlistTitle,
          list: self.playlistData.list,
          published: publishedBoolean,
          publishedDate: await self.getServerTimestamp(),
          updatedBy: {
            displayName: self.$user.displayName,
            email: self.$user.email,
            uid: self.$user.uid,
          },
          workflows: self.getPlaylistWorkflows(self.playlistData.list),
          poster: self.playlistData.poster || null,
        };
        if (self.playlistData.certifiers) {
          data.certifiers = self.playlistData.certifiers;
        }
        // debugger
        self
          .updateDocument("playlists", id, data)
          .then(() => {
            self.playlistData.published = publishedBoolean;
            self.madeChanges = false;
            self.alert.isSaving = false;
            if (todo == "publish") {
              self.alert.alertText = self.$t("skills.alertSkillSuccessfullyPublished");
              if (self.playlistData.dueActivated) {
                self.updateSkillDueWhenPublish({ isStartAtReset: false });
              }
              notifySkillHasChangedPublishedState({
                playlistId: id,
                isPublished: true,
                vueInstanceRef: self,
              });
            } else if (todo == "unpublish") {
              self.alert.alertText = self.$t("skills.alertSkillSuccessfullyUnpublished");
              notifySkillHasChangedPublishedState({
                playlistId: id,
                isPublished: false,
                vueInstanceRef: self,
              });
            } else {
              self.alert.alertText = self.$t("skills.alertSkillSuccessfullySaved");
            }
            self.alert.isSuccess = true;
            resolve();
          })
          .catch((err) => {
            self.alert.isSaving = false;
            self.alert.isNotSuccess = true;
            reject();
          });
      });
    },
    // for v-toolbar
    clickClosePlaylistBuilder() {
      if (this.madeChanges) {
        this.closeConfirmation = true;
      } else {
        this.closePlaylistBuilder();
      }
    },
    closePlaylistBuilder() {
      const workspaceId = this.playlistData.group;
      this.$router.push(`/skills/explorer/${workspaceId}`);
    },
    clickTitle() {
      if (!this.playlistData.published) {
        this.showEditIcon = false;
        setTimeout(() => {
          this.editPlaylistName();
        }, 100);
      }
    },
    editPlaylistName() {
      this.isEditingPlaylistName = true;
      this.editedPlaylistTitle = this.playlistData.playlistTitle;
      this.$nextTick(() => this.$refs.inputPlaylistTitle.focus());
    },
    finishEditing() {
      this.updatePlaylistTitle();
      if (!this.isTypingTitle && this.playlistData.playlistTitle != "") {
        this.isEditingPlaylistName = false;
      }
    },
    handleInput(event) {
      // finish editing by hitting return key
      if (event.keyCode == 13) {
        //event.keyCode == 13 is to add line breaks
        event.preventDefault();
        this.updatePlaylistTitle();
        this.isEditingPlaylistName = false;
        this.isEditingStepName = false;
      }
    },
    updatePlaylistTitle() {
      this.playlistTitleNotAllowed
        ? (this.editedPlaylistTitle = this.playlistData.playlistTitle)
        : (this.playlistData.playlistTitle = this.editedPlaylistTitle);
    },
    openAddWorkflow() {
      if (!this.initWorkflowSelector) {
        this.initWorkflowSelector = true;
      }
      if (this.playlistData.published) {
        this.showEditPlaylistPopup = true;
      } else {
        this.showWorkflowSelector = true;
      }
    },
    onclickEditPoster() {
      if (this.playlistData.published) {
        this.showEditPlaylistPopup = true;
      } else {
        this.showPosterPopup = true;
      }
    },
    addMoreWorkflow(workflows) {
      workflows.forEach((workflow) => {
        //right now we only support workflow as skill item, but in the future, there could be ohter types of skill items
        const item = {
          type: "workflows",
          title: workflow.title,
          poster: workflow.poster,
          videoDuration: workflow.videoDuration || 0,
          steps: workflow.steps.length,
          id: workflow.id,
          published: workflow.published,
          titleTranslations: workflow.titleTranslations || {},
          privateWorkspace: workflow.privateWorkspace || false,
        };
        this.playlistData.list.push(item);
      });
      this.showWorkflowSelector = false;
      this.madeChanges = true;
    },
    //// skill due ////
    updateUsersAndTeamsDueDates() {
      this.updateSkillDueWhenPublish({ isStartAtReset: false });
    },
    async updateSkillDueWhenPublish({ isStartAtReset }) {
      if (!this.playlistData.dueActivated) {
        return;
      }
      // "isStartAtReset: true" means to reset the dueStartAt to current date
      const skillId = this.playlistData.id;
      const requestBody = {
        dueActivated: true,
        dueDays: this.playlistData.dueDays,
        isStartAtReset: isStartAtReset,
        languageCode: this.$i18n.locale,
        baseUrl: this.$baseUrl,
      };
      const { ok } = await saveUpdateSkillDue({ skillId, requestBody });
      if (ok) {
        this.alertDue = {
          show: true,
          type: "success",
          msg: this.$t("skills.alertSuccessNewDeadline"),
        };
        this.readyToGetExistingPlayListData(this.playlistId); // get updated due days for all the users
      } else {
        this.alertDue = {
          show: true,
          type: "error",
          msg: this.$t("skills.alertFailedDeadline"),
        };
      }
    },
  },
  computed: {
    ...mapGetters("permission", ["getter_quiz_enabled"]),
    playlistTitleNotAllowed() {
      return checkPlaylistTitleCharacters(this.editedPlaylistTitle) || this.editedPlaylistTitle.length === 0;
    },
    isQuizEnable() {
      return this.getter_quiz_enabled;
    },
    playlistPosterUrl() {
      if (this.playlistData) {
        if (this.playlistData.poster) {
          return this.playlistData.poster;
        } else if (this.playlistData.list) {
          const showWorkflowPoster =
            this.playlistData.list[0] && this.playlistData.list[0].poster && !this.playlistData.list[0].deleted;
          return showWorkflowPoster ? this.playlistData.list[0].poster : "";
        }
      }
      return "";
    },
    workflows() {
      return this.$store.state.workflows;
    },
    tabs() {
      return [
        { name: "SkillsBuilder", text: this.$t("skills.content"), autotest: "skillsbuilder-content", subpath: "" },
        { name: "SkillsBuilderUsers", text: this.$t("all.users"), autotest: "skillsbuilder-users", subpath: "users" },
        ...(this.isQuizEnable
          ? [
              {
                name: "SkillsBuilderQuizAnalytics",
                text: this.$t("skills.quizAnalytics"),
                autotest: "skillsbuilder-quiz-analytics",
                subpath: "quiz-analytics",
              },
            ]
          : []),

        {
          name: "SkillsBuilderSettings",
          text: this.$t("admin.settings"),
          autotest: "skillsbuilder-settings",
          subpath: "settings",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.playerlist-builder {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #fbfbfb;
}
/* for toolbar */
.builder-toolbar {
  z-index: 2;
}
.tool-bar-icon {
  color: #52545d;
  height: 28px;
  width: 28px;
  padding: 3px;
  margin-right: 18px;
}
.tool-bar-icon:hover {
  color: white;
}
.edit-poster-div {
  position: relative;
  width: 80px;
  min-width: 80px;
  margin-right: 16px;
  cursor: pointer;
  background: #1e1f22;
}
.poster-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0px;
}
.poster-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 14px;
  opacity: 1;
  border: grey 1px dashed;
}
.edit-poster-div:hover .poster-icon {
  opacity: 1;
  transition: 0.3s;
  z-index: 2;
}
.video-poster {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  z-index: 1;
}
.title-border {
  padding: 4px;
  border: transparent 1px dashed;
  margin-left: -4px;
}
.title-border:hover {
  border: #55565a 1px dashed;
  cursor: pointer;
  transition: 0.3s;
}
.editVideo__editInputWrapper {
  position: relative;
}
.editVideo__editInput--invalid {
  display: flex;
  width: 100px;
  position: absolute;
  bottom: -20px;
  left: 5px;
  font-size: 12px;
}
.edit-video-title {
  pointer-events: auto;
  padding: 0 8px;
  color: white;
  font-size: 20px;
  line-height: 22px;
  overflow-y: auto;
  background-color: #0c0c0e;
  margin-right: 10px;
  margin-top: 2px;
  border-bottom: #2c2d32 2px solid;
}
.title-empty {
  border-bottom: #e03535 2px solid !important;
}
.edit-video-title:focus {
  outline-width: 0;
}
.edit-video-title::selection {
  color: white;
  background: #4689f4; /* WebKit/Blink Browsers */
}
.edit-video-title::-moz-selection {
  color: white;
  background: #4689f4; /* Gecko Browsers */
}
.edit-icon {
  opacity: 0;
  position: relative;
  left: -20px;
}
.edit-icon-show {
  left: 0;
  opacity: 1;
  transition: 0.3s;
}
.publish-tag {
  min-width: 104px;
  height: 24px;
  margin: 0 20px 0 10px;
  background-color: #202125;
  padding: 1px 11px;
  border-radius: 16px;
  position: relative;
  left: -28px;
}
.publish-tag-move {
  left: 0;
  transition: 0.3s;
}
.publish-tag-text {
  font-size: 12px;
  line-height: 12px;
}
.red-dot {
  height: 7px;
  width: 7px;
  background-color: #e03535;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.green-dot {
  height: 7px;
  width: 7px;
  background-color: #0ef28f;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.btn-width {
  min-width: 120px;
}
.no-transform {
  text-transform: none !important;
}
.btn-width-lg {
  min-width: 140px;
  text-transform: none !important;
}
.tabs-div {
  $max-num-of-tabs: 4;
  --num-of-tabs: 4;
  position: relative;
  flex-direction: row;
  display: flex;
  margin: 10px auto;
  height: 58px;
  width: 600px;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  &__disabled {
    pointer-events: none;
  }

  .blue-bar {
    position: absolute;
    bottom: 0;
    width: calc(100% / var(--num-of-tabs));
    border-bottom: 4px solid #4689f3;
    transition: 0.3s;
    padding: 0 1rem;
  }

  .tab-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-decoration: none;
    height: 100%;
    color: #646464;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    opacity: 0.7;
    &.tab-btn--active {
      opacity: 1;
    }

    @for $i from 1 through $max-num-of-tabs {
      &.tab-btn--active:nth-child(#{$i}) ~ .blue-bar {
        left: calc(100% / var(--num-of-tabs) * ($i - 1));
      }
    }
  }
}

/* for list builder */

.new-list-msg {
  position: absolute;
  height: calc(100% - 140px);
  width: 100%;
  top: 140px;
  left: 0;
  z-index: 0;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.add-new-workflow-div {
  min-width: 400px;
  margin: 0 auto;
}

.new-list-msg-text {
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.add-div {
  position: relative;
  width: 100%;
  text-align: right;
  margin-top: 68px;
}
.add-center {
  position: relative;
  width: 90%;
  max-width: 1052px;
  margin: 0 auto;
}
.add-workflow-btn {
  position: relative;
  top: 0;
  right: 30px;
  display: inline-block;
}
/* skill-wrapper */
.playlist-wrapper {
  position: absolute;
  height: calc(100% - 250px);
  width: 100%;
  top: 250px;
  left: 0;
  overflow: auto;
  padding: 0 0 16px 0;
}
.playlist-wrapper::-webkit-scrollbar {
  width: 8px;
}
.playlist-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}

.playlist-all-div {
  position: relative;
  width: 90%;
  max-width: 1052px;
  margin: 0 auto;
}

.router-div {
  position: absolute;
  z-index: 3;
}
</style>
